
.breadcrumb {

    &::after {
        content: "";
        margin-top: 5px;
        display: block;
        width: 240px;
        height: 2px;

        background: url("../img/breadcrumb-divider-bgi.png") left top repeat-x;
    }
}

.breadcrumb-item {
    font-size: 20px;

    a {
        color: $red;
        text-decoration: none;
    }

    & + .breadcrumb-item::before {
        color: $red;
    }
}
