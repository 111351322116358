
.header {
    margin-bottom: -65px;
    position: relative;
    z-index: 5;
    overflow: hidden;

    background: url("../img/header-bgi.png") center bottom no-repeat;
}

.header-1st {
    min-height: 140px;
    padding-top: 10px;

    background: url("../img/header-1st-bgi.png") center bottom no-repeat;
}

.header-networks {
    padding-top: 30px;

    li a {
        display: block;
        width: 26px;
        height: 26px;

        border-radius: 50%;

        background-color: rgba($color: $white, $alpha: .5);

        color: $white;
        font-size: 12px;
        line-height: 26px;
        text-align: center;

        transition: all .35s ease;

        &:hover {
            background-color: $white;

            color: $red;
        }
    }
}

.header-2nd {
    position: relative;
    z-index: 5;
    min-height: 90px;
    margin-top: -25px;
    padding-bottom: 50px;

    .navbar-toggler {
        border: none;
        box-shadow: none !important;
    }

    .navbar-nav {
        width: 100% !important;

        @include media-breakpoint-up(lg) {
            padding-left: 75px !important;
        }

        @include media-breakpoint-down(lg) {
            margin-top: 10px;
            padding-top: 10px;

            border-top: 2px dashed $red;
        }
    }

    .nav-item {

        @include media-breakpoint-down(lg) {
            padding-inline-start: 10px;
            padding-inline-end: 10px;

            & + .nav-item {
                padding-top: 8px;

                border-top: 1px solid #e6e6e6;
            }
        }
    }

    .nav-link {
        overflow: hidden;
        display: inline-block;
        position: relative;

        color: $nav-link-color;
        line-height: 1.1;
        font-weight: bold;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {

            &::before,
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                left: 0;
            }

            &::before {
                background-color: $red;
                height: 2px;
                bottom: 0;
                transform-origin: 100% 50%;
                transform: scaleX(0);
                transition: transform .35s cubic-bezier(0.76, 0, 0.24, 1);
            }

            &::after {
                content: attr(data-replace);
                height: 100%;
                top: 50%;
                margin-top: -9px;
                transform-origin: 100% 50%;
                transform: translate3d(200%, 0, 0);
                transition: transform .35s cubic-bezier(0.76, 0, 0.24, 1);
                text-align: center;
                color: $red;
            }

            &:hover::before {
                transform-origin: 0% 50%;
                transform: scaleX(1);
            }

            &:hover::after {
                transform: translate3d(0, 0, 0);
            }

            & > span {
                display: inline-block;
                transition: transform .35s cubic-bezier(0.76, 0, 0.24, 1);
            }

            &:hover span {
                transform: translate3d(-200%, 0, 0);
            }
        }
    }

    .nav-hidden {
        opacity: 0;
    }
}
