.content {
    overflow: hidden;

    h1, h2, h3,
    h4, h5, h6 {
        color: $red;
        font-family: "Denk One", sans-serif;
    }

    .btn-custom,
    .link-more {
        position: relative;
        display: inline-block;
        padding: 2px 30px 8px;

        border: none;
        border-radius: 8px;

        background-color: $yellow;

        color: $red;
        font-family: "Caveat", sans-serif;
        font-size: 24px;
        line-height: 1.2;
        font-weight: bold;
        text-decoration: none;

        transition: all .25s;

        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 4px;
            top: -4px;

            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23e30913' stroke-width='3' stroke-dasharray='4%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            // border: 1px dashed $red;
            border-radius: 8px;

            transition: all .25s;
        }

        &:hover {

            &::after {
                left: 0;
                top: 0;
            }
        }
    }

    .btn-custom {
        padding: 3px 50px 10px;
    }

    .form {
        padding: 70px 60px;

        border-radius: 25px;

        background-color: #e0e0e0;

        color: #373636;
        font-size: 22px;
        line-height: 1.5;
    }
}

.content-heading {
    margin-bottom: 65px;

    text-align: center;

    p {
        color: #838181;
        font-size: 24px;
    }

    h3 {
        display: inline-block;
        position: relative;
        margin-bottom: 15px;
        padding: 0 50px;

        font-size: 34px;
        text-transform: uppercase;

        &::before,
        &::after {
            content: "";
            position: absolute;
            margin-top: -10px;
            top: 50%;

            width: 36px;
            height: 19px;
        }

        &::before {
            left: 0;

            background: url("../img/content-heading-bgi-left.png") left center no-repeat;
        }

        &::after {
            right: 0;

            background: url("../img/content-heading-bgi-right.png") right center no-repeat;
        }
    }
}

.content-about {
    padding-top: 70px;
    position: relative;
    overflow: hidden;

    .more {

        span {
            padding-left: 10px;
            padding-right: 10px;

            &::before {
                content: "";
                display: block;
                width: 100%;

                border-top: 2px dashed $red;
            }
        }

        b {
            color: $red;
        }
    }

    .container {
        position: relative;
    }

    .icon-leaf,
    .icon-spoon {
        position: absolute;
        z-index: 5;

        background-repeat: no-repeat;
        background-position: center center;
    }

    .icon-spoon {
        width: 135px;
        height: 149px;
        top: 210px;
        left: 0;

        background-image: url("../img/content-spoon.png");
    }

    .icon-leaf {
        width: 122px;
        height: 164px;
        top: -60px;
        right: -135px;

        background-image: url("../img/content-leaf2.png");
    }
}

.content-products {
    position: relative;
    z-index: 5;
    margin-bottom: -90px;
    padding: 89px 0 52px;

    background-image: url("../img/content-products-bgi-top.png"), url("../img/content-products-bgi-bottom.png");
    background-position: center top, center bottom;
    background-repeat: no-repeat, no-repeat;

    .wrapper {
        position: relative;
        overflow: hidden;
        padding: 60px 0 110px;

        background: linear-gradient(0deg, rgba(231,231,231,1) 0%, rgba(245,245,245,1) 35%, rgba(231,231,231,1) 100%);

        > div {
            position: relative;
            z-index: 5;
        }

        &::before,
        &::after {
            content: "";
            position: absolute;

            @include media-breakpoint-down(xxl) {
                display: none;
            }
        }

        &::before {
            width: 608px;
            height: 675px;
            top: 65px;
            right: 1150px;

            background: url("../img/content-products-bgi-milk-left.png") left top no-repeat;
        }

        &::after {
            width: 586px;
            height: 569px;
            left: 1150px;
            bottom: 0;

            background: url("../img/content-products-bgi-milk-right.png") right bottom no-repeat;
        }
    }
}

.content-recipes {
    position: relative;
    padding: 150px 0 210px;

    background: url("../img/content-recipes-bgi.png") left top repeat;

    .container {
        position: relative;
    }

    .icon-spoon,
    .icon-garlic,
    .icon-clove-right,
    .icon-clove-bottom,
    .icon-leaf {
        position: absolute;
        z-index: 5;

        background-repeat: no-repeat;
        background-position: center center;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .icon-spoon {
        top: 175px;
        right: 0;
        width: 212px;
        height: 185px;

        background-image: url("../img/img-spoon.png");

        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    .icon-leaf {
        bottom: 65px;
        left: 0;
        width: 179px;
        height: 184px;

        background-image: url("../img/content-leaf.png");
    }

    .icon-garlic {
        top: 45%;
        left: -45px;
        width: 87px;
        height: 43px;

        background-image: url("../img/content-garlic.png");
    }

    .icon-clove-right {
        top: 55%;
        right: -40px;
        width: 105px;
        height: 142px;

        background-image: url("../img/content-clove-right.png");
    }

    .icon-clove-bottom {
        bottom: -65px;
        left: 200px;
        width: 36px;
        height: 55px;

        background-image: url("../img/content-clove-bottom.png");
    }

    [class^="col-"] {
        padding-left: 30px;
        padding-right: 30px;
    }

    [class^="col-"]:first-child .recipe.teaser {
        transform: rotate(358deg);

        &:hover {
            transform: rotate(360deg);
        }
    }

    [class^="col-"]:nth-child(2) .recipe.teaser {
        transform: rotate(2deg);

        &:hover {
            transform: rotate(0);
        }
    }

    [class^="col-"]:last-child .recipe.teaser {
        transform: rotate(358deg);

        &:hover {
            transform: rotate(360deg);
        }
    }
}

.content-pro {
    position: relative;
    z-index: 5;
    margin: -80px 0 -135px;
    height: 675px;

    background: url("../img/content-pro-bgi.png") center top no-repeat;

    [class^="col-"]:first-child {
        padding-top: 380px;
    }

    [class^="col-"]:last-child {
        padding-top: 200px;
    }

    .link-more::after {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23fff' stroke-width='3' stroke-dasharray='4%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }

    .content-link {
        position: relative;
        display: inline-block;

        &::before,
        &::after  {
            content: "";
            position: absolute;
            z-index: 5;

            background-position: center center;
            background-repeat: no-repeat;
        }

        &::before {
            width: 20px;
            height: 20px;
            top: -20px;
            left: -25px;

            background-image: url("../img/content-cross-bgi.png");

            transition: all .35s ease;
        }

        &::after {
            width: 24px;
            height: 24px;
            bottom: -20px;
            right: -25px;

            background-image: url("../img/content-cross-2-bgi.png");

            transition: all .35s ease;
        }
    }

    &:hover .content-link {

        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }

    @include media-breakpoint-down(lg) {
        background-position: 30% top;
    }
}

.content-news {
    position: relative;
    padding-top: 145px;
    padding-bottom: 90px;

    text-align: center;

    .container {
        position: relative;

        .icon-like,
        .icon-heart,
        .icon-hushed,
        .icon-heart-eyes {
            position: absolute;
            z-index: 10;

            border-radius: 50%;

            background-repeat: no-repeat;
            background-position: center center;

            animation: float 6s ease-in-out infinite;

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        .icon-like {
            top: -50px;
            right: -30px;
            width: 88px;
            height: 88px;

            background-image: url("../img/icon-like.png");

            animation-delay: 2s !important;
        }

        .icon-heart {
            top: 30px;
            left: -30px;
            width: 86px;
            height: 85px;

            background-image: url("../img/icon-heart.png");

            @include media-breakpoint-down(lg) {
                top: 460px;
            }
        }

        .icon-heart-eyes {
            bottom: -40px;
            right: 20px;
            width: 138px;
            height: 138px;

            background-image: url("../img/icon-heart-eyes.png");

            animation-delay: 4s !important;
        }
    }

    .row {
        position: relative;
    }

    .content-tile {
        display: inline-block;
        margin: 0 auto 30px;

        * {
            font-family: "Caveat", sans-serif !important;
        }

        > a {
            display: block;

            text-align: center;
        }

        a {
            color: $white;
            text-decoration: none;
        }

        img {
            border-radius: 10px 10px 0 0;
        }

        &:hover {
            animation-name: pop;
            animation-duration: .35s;
            animation-fill-direction: forward;
        }
    }

    .content-tile-footer {
        position: relative;
        z-index: 5;
        margin-top: -22px;
        padding: 20px;

        border-radius: 0 0 10px 10px;

        background: url("../img/article-footer-bgi.png") center top no-repeat;

        i {
            margin-bottom: 10px;

            font-size: 32px;
        }

        h3 {
            overflow: hidden;

            color: $white;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.content-more {
    margin-top: 30px;

    text-align: center;
}
