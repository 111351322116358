.page-contact {
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 150px;

    background: url("../img/content-recipes-bgi.png") left top repeat;

    .form-submit {
        display: inline-block;
        min-height: 36px;
        padding: 10px 65px 0 0;

        background: url("../img/form-submit.png") right top no-repeat;
    }

    .container {
        position: relative;
    }

    .icon-logo,
    .icon-cheese-bottom-center,
    .icon-cheese-bottom-left,
    .icon-cheese-top-right,
    .icon-cheese-top-left,
    .icon-leaf,
    .icon-waves {
        position: absolute;
        z-index: 5;

        background-repeat: no-repeat;
        background-position: center center;
    }

    .icon-cheese-bottom-center,
    .icon-cheese-bottom-left,
    .icon-cheese-top-right,
    .icon-cheese-top-left,
    .icon-waves,
    .icon-leaf {
        animation: float 6s ease-in-out infinite;
    }

    .icon-logo {
        width: 139px;
        height: 114px;
        top: -50px;
        right: 125px;

        background-image: url("../img/logo.png");
    }

    .icon-cheese-top-right {
        width: 257px;
        height: 165px;
        top: 135px;
        right: -230px;

        background-image: url("../img/content-cheese-top-right.png");
    }

    .icon-cheese-top-left {
        width: 199px;
        height: 107px;
        top: 15px;
        left: -155px;

        background-image: url("../img/content-cheese-top-left.png");

        animation-delay: 4s !important;
    }

    .icon-cheese-bottom-center {
        width: 146px;
        height: 106px;
        bottom: -60px;
        right: 50%;

        background-image: url("../img/content-cheese-bottom-center.png");

        animation-delay: 2s !important;
    }

    .icon-cheese-bottom-left {
        width: 161px;
        height: 128px;
        bottom: 80px;
        left: -250px;

        background-image: url("../img/content-cheese-bottom-left.png");

        animation-delay: 6s !important;
    }

    .icon-waves {
        width: 80px;
        height: 48px;
        bottom: 5px;
        right: -185px;

        background-image: url("../img/content-waves.png");

        animation-delay: 4s !important;
    }

    .icon-leaf {
        width: 121px;
        height: 191px;
        top: -170px;
        left: -195px;

        background-image: url("../img/content-leaf3.png");

        animation-delay: 2s !important;
    }
}

.page-pro {
    @extend .page-contact;

    .wrapper {
        border-radius: 25px;

        background-color: #e0e0e0;

        @include media-breakpoint-up(lg) {

            [class^="col-"]:first-child {
                height: 620px;
                overflow-y: auto;

                border-radius: 25px 0 0 25px;
            }
        }
    }

    .icon-logo {
        z-index: 999;
    }

    #map {

        @include media-breakpoint-up(lg) {
            border-radius: 0 25px 25px 0;
        }
    }

    .card {
        border: none;
        border-radius: 7px;

        cursor: pointer;
    }

    .card-title {
        text-transform: uppercase;
    }

    .card-body {

        [class^="icon-"] {
            font-size: 32px;
            line-height: 1.1;
            color: $red;

            &::before {
                margin: 0;
            }
        }
    }
}

.page-products {
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 150px;

    background: url("../img/content-recipes-bgi.png") left top repeat;

    .container:nth-child(2) {
        position: relative;

        .row {
            position: relative;
            z-index: 5;
        }

        .icon-leaf,
        .icon-waves,
        .icon-cheese,
        .icon-cheese2 {
            position: absolute;

            background-repeat: no-repeat;
            background-position: center center;

            animation: float 6s ease-in-out infinite;
        }

        .icon-cheese {
            width: 175px;
            height: 204px;
            top: -60px;
            right: -285px;

            background-image: url("../img/content-products-bgi-cheese.png");

            animation-delay: 2s !important;
        }

        .icon-cheese2 {
            width: 89px;
            height: 101px;
            top: 45px;
            left: -55px;

            background-image: url("../img/content-products-bgi-cheese2.png");
        }

        .icon-leaf {
            width: 200px;
            height: 206px;
            bottom: 80px;
            left: -265px;

            background-image: url("../img/content-products-bgi-leaf.png");
        }

        .icon-waves {
            width: 80px;
            height: 48px;
            right: -140px;
            top: 50%;

            background-image: url("../img/content-waves.png");

            animation-delay: 6s !important;
        }
    }

    .content-recipes {
        position: relative;
        padding-bottom: 0;

        .row {
            margin-top: -120px;
            position: relative;
            z-index: 5;
        }

        .icon-spoon {
            top: 40%;
        }

        .icon-garlic {
            top: initial;
            left: -85px;
            bottom: 210px;
        }

        .icon-clove-right {
            top: initial;
            right: -105px;
            bottom: 120px;
        }
    }

    .nav.nav-pills {
        display: inline-flex;
        margin-bottom: 50px;
        position: relative;

        &::before {
            content: "Trier par";
            position: absolute;
            top: -60px;
            left: -50px;

            color: $red;
            font-size: 28px;
            line-height: 1.1;
            font-family: "Caveat", sans-serif;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 25px;
            height: 50px;
            top: -25px;
            left: -35px;

            background: url("../img/arrow.png") center center no-repeat;
        }

        .nav-link {
            font-family: "Denk One", sans-serif;

            &:not(.active) {
                background-color: #ededed;

                color: $red;
            }

            &.active {
                background-color: $blue;
            }
        }

        .nav-item + .nav-item {
            margin-inline-start: 35px;
        }
    }
}

.page-product {
    padding: 120px 0 90px;

    background: #f0e40b url('../img/content-product-details-bgi.jpg') center bottom no-repeat;

    .icon-leaf,
    .icon-leaf2 {
        position: absolute;

        background-position: center center;
        background-repeat: no-repeat;

        animation: float 6s ease-in-out infinite;
    }

    .icon-leaf {
        width: 121px;
        height: 191px;
        left: -200px;
        top: 0;

        background-image: url("../img/content-leaf3.png");
    }

    .icon-leaf2 {
        width: 122px;
        height: 164px;
        right: -200px;
        bottom: 0;

        background-image: url("../img/content-leaf2.png");

        animation-delay: 2s !important;
    }

    .container {
        position: relative;
    }

    h1 {
        margin-bottom: 40px;
        position: relative;

        color: $red;
        font-size: 36px;
        font-family: "Denk One", sans-serif;

        &::after {
            content: "";
            position: absolute;
            width: 42px;
            height: 25px;
            left: -60px;
            top: 50%;

            background: url("../img/content-waves.png") left center no-repeat;
            background-size: cover;

            transform: translateY(-50%);
        }
    }

    h3 {
        color: $blue;
        font-size: 20px;
        font-family: "Denk One", sans-serif;
    }

    p {
        width: 280px;
        margin-bottom: 40px;

        font-size: 18px;
    }

    .specs {
        position: relative;
        width: 380px;
        padding: 45px 30px 25px;

        border: 2px dashed $blue;
        border-radius: 15px;

        h3 {
            width: 210px;
            position: absolute;
            top: -20px;
            left: -2px;
            padding-right: 10px;
            padding-bottom: 5px;

            background-color: $yellow;
        }

        table {
            width: 100%;

            td:last-child {
                text-align: end;
            }
        }
    }
}

.page-featured {
    margin: 0 auto;
    padding: 45px 0 95px;

    text-align: center;

    h3 {
        display: inline-block;
        margin-bottom: 50px;

        font-size: 25px;
        text-align: center;

        &::before {
            content: "";
            display: inline-block;
            width: 36px;
            height: 19px;
            margin-inline-end: 15px;

            background: url("../img/content-heading-bgi-left.png") left center no-repeat;
        }

        &::after {
            content: "";
            display: inline-block;
            width: 36px;
            height: 19px;
            margin-inline-start: 15px;

            background: url("../img/content-heading-bgi-right.png") left center no-repeat;
        }
    }

    article {
        text-align: center;

        &:hover {
            .thumbnail {
                background-color: $yellow;
            }

            .title {
                color: $blue;
            }
        }
    }

    .thumbnail {
        display: block;
        position: relative;
        width: 135px;
        height: 135px;
        margin: 0 auto 5px;

        border-radius: 50%;

        background-color: #f3f3f3;

        text-align: center;

        transition: background-color .35s ease;

        img {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            height: auto;
            width: auto !important;
            max-width: 100%;

            transform: translateX(-50%) translateY(-50%);
        }
    }

    .title {
        color: #535353;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        text-decoration: none;

        transition: color .35s ease;
    }

    .owl-nav {
        display: block !important;

        button {
            width: 13px;
            height: 19px;
            position: absolute;
            top: 50%;
            overflow: hidden;

            background-repeat: no-repeat !important;
            background-position: center center !important;

            text-indent: -999px;

            transition: color .35s ease;
            transform: translateY(-50%);

            &:hover {
                color: $blue !important;
            }

            &.owl-prev {
                left: 0;

                background-image: url("../img/arrow-left.png") !important;
            }

            &.owl-next {
                right: 0;

                background-image: url("../img/arrow-right.png") !important;
            }
        }
    }

    .owl-carousel {
        position: relative;
        padding-left: 30px;
        padding-right: 30px;
    }
}

.products-related {
    text-align: center;

    h3 {
        display: inline-block;
        margin: 30px auto 15px;
        padding-left: 35px;

        background: url("../img/produ ct-arrow-down.png") left center no-repeat;

        color: $red;
        text-align: center;
        font-family: "Denk One", sans-serif;

        &::after {
            content: "";
            display: block;
            width: 145px;
            height: 12px;
            margin: 10px auto 0;

            background: url("../img/content-product-featured-underline.png") center top no-repeat;
        }
    }

    article {
        max-width: 120px;
        margin: 0 auto;

        h4 {
            margin-top: 10px;

            color: $blue;
            font-size: 15px;
            font-weight: 600;
        }
    }

    .thumbnail {
        position: relative;

        .weight {
            position: absolute;
            right: 100px;
            bottom: -30px;
            padding: 6px 12px;

            border-radius: 8px;

            background-color: #fff;

            color: $red;
            font-size: 18px;
            font-family: "Denk One", sans-serif;

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: -4px;
                top: -4px;
                z-index: 5;

                border-radius: 8px;
                border: 1px dashed $red;
            }
        }
    }
}
