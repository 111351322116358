$red: #e30913;
$yellow: #f0e40b;
$blue: #0b57e1;
$body-color: #595959;
$font-family-base: "Lato", sans-serif;
$nav-link-color: #606060;
$nav-link-font-size: 18px;
$focus-ring-color: rgba($red, $focus-ring-opacity);
$input-placeholder-color: $red;
$input-font-size-lg: 14px;
$input-padding-x-lg: 15px;
$input-padding-y-lg: 12px;
$input-border-radius-lg: 7px;
$breadcrumb-divider: ">";
