.product.teaser {
    position: relative;
    padding-bottom: 68px;

    box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 0px 19px -1px rgba(0,0,0,0.25);
    border-radius: 12px;

    &:hover {

        .product-thumbnail img {
            transform: scale(1.08);
        }

        .product-footer {
            height: 125px;

            .arrow {
                opacity: 1;
                transform: rotate(0deg);
            }
        }
    }

    .product-thumbnail {
        overflow: hidden;

        border-radius: 12px 12px 0 0;

        img {
            border-radius: 12px 12px 0 0;

            transition: all .35s ease;
        }
    }

    .product-title {
        align-self: flex-end;
        position: relative;
        z-index: 10;
        width: 100%;
        overflow: hidden;

        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;

        a {
            color: $white;
            text-decoration: none;
        }
    }

    .product-footer {
        position: absolute;
        display: flex;
        height: 100px;
        z-index: 5;
        left: 50%;
        bottom: 0;
        width: 100%;
        padding: 35px 15px 15px;

        background: url("../img/product-footer-bgi.png") center top no-repeat;

        border-radius: 0 0 12px 12px;

        transform: translateX(-50%);
        transition: all .5s ease;

        .arrow {
            opacity: 0;
            position: absolute;
            left: 50%;
            margin-left: -16px;
            top: 15px;
            width: 32px;
            height: 49px;

            background: url("../img/arrow-white.png") center center no-repeat;

            transition: all .75s ease;
            transform: rotate(45deg);
        }
    }
}
