
.recipe.teaser {
    position: relative;
    margin-bottom: 35px;

    box-shadow: 0px 5px 15px 0px rgba($color: $black, $alpha: 0.35);
    -webkit-box-shadow: 0px 5px 15px 0px rgba($color: $black, $alpha: 0.35);
    -moz-box-shadow: 0px 5px 15px 0px rgba($color: $black, $alpha: 0.35);
    border-radius: 10px;

    transition: all .35s ease;

    .recipe-share {
        display: block;
        position: absolute;
        z-index: 10;
        top: -20px;
        right: -20px;
        width: 40px;
        height: 40px;

        border-radius: 50%;

        background-color: $red;

        color: $white;
        font-size: 20px;
        line-height: 40px;
        font-weight: normal;
        text-align: center;
    }

    .recipe-thumbnail {
        display: block;
        overflow: hidden;

        text-align: center;

        img {
            transition: all .35s ease-in-out;
        }
    }

    .recipe-content {
        padding: 10px 10px 0;

        border-radius: 10px 10px 0 0;

        background-color: $white;
    }

    .recipe-title {
        overflow: hidden;

        font-family: "Caveat", sans-serif;
        font-size: 28px;
        line-height: 1.2;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;

        a {
            color: $white;
            text-decoration: none;
        }
    }

    .recipe-duration {
        margin-bottom: 0;

        color: $yellow;
        font-size: 18px;
        line-height: 1.2;
        text-align: center;

        [class^="icon-"] {
            font-size: 21px;
            line-height: 1;
        }
    }

    .recipe-footer {
        position: relative;
        z-index: 5;
        margin-top: -22px;
        padding: 30px 20px 15px;

        border-radius: 0 0 10px 10px;

        background: url("../img/article-footer-bgi.png") center top no-repeat;
    }

    &:hover .recipe-thumbnail img {
        transform: scale(1.1);
    }
}
