@font-face {
    font-family: 'fontello';
    src: url('../font/fontello/fontello.eot?84287155');
    src: url('../font/fontello/fontello.eot?84287155#iefix') format('embedded-opentype'),
        url('../font/fontello/fontello.woff2?84287155') format('woff2'),
        url('../font/fontello/fontello.woff?84287155') format('woff'),
        url('../font/fontello/fontello.ttf?84287155') format('truetype'),
        url('../font/fontello/fontello.svg?84287155#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'fontello';
      src: url('../font/fontello.svg?84287155#fontello') format('svg');
    }
  }
  */
[class^="icon-"]::before,
[class*=" icon-"]::before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-calendar:before { content: '\e800'; } /* '' */
.icon-clock:before { content: '\e801'; } /* '' */
.icon-share:before { content: '\e802'; } /* '' */
.icon-location:before { content: '\f031'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */

/* '' */
