
$footer-text-color: #606060;
$footer-heading-color: #3a3a3a;
$footer-border-color: #e6e6e6;

.footer {
    position: relative;

    background: url('../img/footer-bgi.png') left top repeat;

    color: $footer-text-color;

    h3 {
        display: inline-block;
        margin-bottom: 20px;
        padding-bottom: 10px;

        border-bottom: 2px solid $red;

        color: $footer-heading-color;
        font-size: 28px;
        text-transform: uppercase;
    }

    a {
        color: $footer-text-color;
        text-decoration: none;

        &:hover {
            color: $red;
        }
    }

    [class^="col-"] {
        padding-inline-start: 85px;
        padding-inline-end: 85px;

        & + [class^="col-"] {

            @include media-breakpoint-down(lg) {
                border-top: 1px solid $footer-border-color;
            }

            @include media-breakpoint-up(lg) {
                border-left: 1px solid $footer-border-color;
            }
        }
    }

    .container {
        position: relative;
    }

    .row {
        padding: 120px 0;
    }

    .icon-hushed,
    .icon-spoon,
    .icon-waves,
    .icon-line {
        position: absolute;
        z-index: 5;
    }

    .icon-hushed,
    .icon-spoon {
        background-repeat: no-repeat;
        background-position: center center;
    }

    // .icon-spoon,
    .icon-hushed {
        animation: float 6s ease-in-out infinite 2s;
    }

    .icon-hushed {
        display: none;
        top: -45px;
        left: 275px;
        width: 122px;
        height: 121px;

        background-image: url("../img/icon-hushed.png");

        @include media-breakpoint-down(lg) {
            left: 75px;
        }
    }

    .icon-spoon {
        top: 100px;
        left: 0;
        width: 212px;
        height: 185px;

        background-image: url("../img/img-spoon.png");

        animation-delay: 6s !important;
    }

    .icon-line {
        height: 2px;
        width: 112px;
        bottom: 70px;
        left: 60px;

        background-color: $red;
    }

    .icon-waves {
        top: 185px;
        right: -50px;
        width: 80px;
        height: 48px;

        background-image: url("../img/content-waves.png");
    }
}

.front,
.home {

    .footer .icon-hushed {
        display: block;
    }
}

.footer-nav li {
    color: $footer-text-color;

    a {
        font-weight: bold;
        text-transform: uppercase;
    }

    & + li {
        margin-top: 12px;
    }

    &:last-child a {
        color: $red;
    }
}

.footer-networks {
    margin-top: 90px;

    li a {
        display: inline-block;
        width: 44px;
        height: 44px;

        border-radius: 50%;

        background-color: $red;

        color: $white !important;
        font-size: 24px;
        line-height: 44px;
        text-align: center;

        transition: all .35s ease;

        &:hover {
            background-color: $yellow;
        }
    }
}

.footer-contact {

    h6 {
        margin-bottom: 3px;

        font-weight: bold;
    }

    li + li {
        margin-top: 22px;
    }
}
