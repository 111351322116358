@font-face {
    font-family: 'Darkones';
    src: url('../font/darkones/darkones-regular.eot');
    src: url('../font/darkones/darkones-regular.eot?#iefix') format('embedded-opentype'),
        url('../font/darkones/darkones-regular.woff2') format('woff2'),
        url('../font/darkones/darkones-regular.woff') format('woff'),
        url('../font/darkones/darkones-regular.ttf') format('truetype'),
        url('../font/darkones/darkones-regular.svg#Darkones-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

