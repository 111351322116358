.front .highlighted {
    height: 763px;

    background: url("../img/banner.jpg") center top no-repeat;
}

.highlighted {
    width: 100%;
    overflow: hidden;
}
