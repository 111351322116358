@keyframes float {

	0% {
		transform: translatey(0px);
	}

	50% {
		transform: translatey(-20px);
	}

	100% {
		transform: translatey(0px);
	}
}

@keyframes pop {

    0%,
    100% {
        transform: scale(1);
    }

    33% {
        transform: scale(1.02);
    }

    66% {
        transform: scale(.98);
    }
}
